const loginLanguage = {
  "zh":{
    "login_account": "登录账号",
    "email": "邮箱",
    "phone": "手机",
    "forget_password": "忘记密码",
    "login": "登录",
    "email_error": "邮箱格式输入错误",
    "password_error": "请输入8位及以上的密码",
    "email_tips":"请输入您的邮箱地址",
    "phone_tips":"请输入您的手机号",
    "password_tips":"请输入8位及以上的密码",
    "update_password_tips":"修改密码及注册需在Aipaper设备上操作",
    "return_login":"返回登录",
    "password_reqired":"密码必填",
    "email_reqired":"邮箱必填",
    "phone_reqired":"手机号必填",
    "please_choose":"区号",
    "please_choose_area":"请选择区号",
    "sms_login":"验证码登录",
    "password_login":"密码登录",
    "verification_tips":"请输入短信验证码",
    "enter_password_tips":"请输入登录密码",
    "verification_code":"获取验证码",
    "reSend": "重发",
    "sending":"发送中"
  },
    "en":{
      "login_account": "Login Account",
      "email": "Email",
      "phone": "Phone",
      "forget_password": "Forget Password",
      "login": "Login",
      "email_error": "Email Error",
      "password_error": "Please enter a password of 8 digits or more",
      "email_tips":"Please enter your email",
      "phone_tips":"Please enter your phone",
      "password_tips":"Please enter a password of 8 digits or more",
      "update_password_tips":"Update password or register on aipaper",
      "return_login":"Back",
      "password_reqired":"Password Reqired",
      "email_reqired":"Email Reqired",
      "phone_reqired":"Phone Reqired",
      "please_choose":"Code",
      "please_choose_area":"Please choose area code",
      "sms_login":"Verification Code Login",
      "password_login":"Password Login",
      "verification_tips":"Please enter sms code",
      "enter_password_tips":"Please enter password",
      "verification_code":"Send",
      "reSend": "ReSend",
      "sending":"sending"
    },

    "de":{
      "login_account": "Konto anmelden",
      "email": "E-Mail",
      "phone": "Telefon",
      "forget_password": "Passwort vergessen",
      "login": "Anmelden",
      "email_error": "E-Mail-Fehler",
      "password_error": "Bitte geben Sie ein 8-stelliges Passwort oder mehr ein",
      "email_tips": "Bitte E-Mail-Adresse eingeben",
      "phone_tips": "Bitte Telefonnummer eingeben",
      "password_tips": "Bitte geben Sie ein 8-stelliges Passwort oder mehr ein",
      "update_password_tips": "Passwort ändern oder bei aipaper registrieren",
      "return_login": "Zurück",
      "password_reqired": "Passwort erforderlich",
      "email_reqired": "E-Mail erforderlich",
      "phone_reqired": "Telefon erforderlich",
      "please_choose": "Bitte Ordner oder Datei auswählen",
      "please_choose_area": "Bitte Vorwahl wählen",
      "sms_login": "Bestätigungscode Anmeldung",
      "password_login": "Passwort Login",
      "verification_tips": "Bitte SMS-Code eingeben",
      "enter_password_tips": "Bitte Passwort eingeben",
      "verification_code": "Abschicken",
      "reSend": "Erneut senden",
      "sending": "Abschicken",
    },
    "fr":{
      "login_account": "Compte de connexion",
      "email": "e-mail",
      "phone": "Téléphone",
      "forget_password": "Oubli du mot de passe",
      "login": "Connexion",
      "email_error": "Erreur d'e-mail",
      "password_error": "Veuillez entrer un mot de passe à 8 chiffres et plus",
      "email_tips": "Veuillez saisir votre e-mail",
      "phone_tips": "Veuillez saisir votre téléphone",
      "password_tips": "Veuillez entrer un mot de passe à 8 chiffres et plus",
      "update_password_tips": "Mettre à jour le mot de passe ou s'inscrire sur AiPaper",
      "return_login": "Retour",
      "password_reqired": "Mot de passe requis",
      "email_reqired": "E-mail requis",
      "phone_reqired": "Téléphone requis",
      "please_choose": "Veuillez choisir un dossier ou un fichier",
      "please_choose_area": "Veuillez choisir l'indicatif régional",
      "sms_login": "Code de vérification Connexion",
      "password_login": "Mot de passe Connexion",
      "verification_tips": "Veuillez entrer le code sms",
      "enter_password_tips": "Veuillez saisir le mot de passe",
      "verification_code": "Envoyer",
      "reSend": "Renvoyer",
      "sending": "envoi"
    },
    "es":{
      "login_account": "Cuenta de inicio de sesión",
      "email": "Correo electrónico",
      "phone": "Teléfono",
      "forget_password": "Contraseña olvidada",
      "login": "Iniciar sesión",
      "email_error": "Error de correo electrónico",
      "password_error": "Introduzca una contraseña de 8 dígitos o más",
      "email_tips": "Por favor, introduce tu email",
      "phone_tips": "Por favor, introduce tu teléfono",
      "password_tips": "Introduzca una contraseña de 8 dígitos o más",
      "update_password_tips": "Actualiza tu contraseña o regístrate en aipaper",
      "return_login": "Volver",
      "password_reqired": "Contraseña Requerida",
      "email_reqired": "Correo Electrónico Requerido",
      "phone_reqired": "Teléfono Requerido",
      "please_choose": "Elige carpeta o archivo",
      "please_choose_area": "Por favor, elige el código de área",
      "sms_login": "Código de verificación Inicio de sesión",
      "password_login": "Contraseña de Inicio de Sesión",
      "verification_tips": "Por favor, introduce el código sms",
      "enter_password_tips": "Por favor, introduce la contraseña",
      "verification_code": "Enviar",
      "reSend": "Reenviar",
      "sending": "enviando"
    }
  }
  export {
    loginLanguage
  }