<template>
      <div>
        <div>
        <img src="@/assets/pc/logo.png" alt="logo" style="width:109px;height:22px;margin-top:37px;margin-left:44px;">&nbsp;
      </div>
      <div id="content" @click="start_choose=false;">
        <div style="  box-shadow: 0 1px 20px rgba(0,0,0,.2);border:2px;border-radius:4px;overflow: hidden;display: flex">
          <div style="width:583px;height: 571px;overflow: hidden;"> 
            <img src="@/assets/pc/flag.png" alt="logo" style="width:583px;height:571px;">&nbsp;
          </div>
        <div  style="width:443px;background-color: #fff;display: flex;align-items: center;justify-content: center;"> 
              <div  v-if="update_pwd==0" style="width: 302px;height: 394px;">
                  <div style="height: 28px;font-weight: 600;font-size: 20px;color: #1D2328;line-height: 28px;text-align: left;font-style: normal;
                  ">{{ll[languageId].login_account}}</div>
                
                  <div style="margin-top:45px;font-size:16px;display: flex;justify-content: flex-start;color: #1D2328;cursor: pointer;">
                  <div :style="type==1?'font-weight: 600;color:#000;border-bottom: 2px solid #000;padding-bottom: 6px;':'font-weight: 500;border-bottom: 2px solid #fff;padding-bottom: 6px;'" @click="type=1;sms_login=false;">{{ll[languageId].email}}</div>
                  <div style="margin-left: 20px;" :style="type==2?'font-weight: 600;color:#000;border-bottom: 2px solid #000;padding-bottom: 6px;':'font-weight: 500;border-bottom: 2px solid #fff;padding-bottom: 6px;'" @click="type=2;">{{ll[languageId].phone}}</div>
                </div>

              <a-form-model  ref="loginFormModel" :model="userInfo" :rules="rules">
                <a-form-model-item  prop="email" v-if="type==1">
                  <a-input size="large" v-model="userInfo.email"  style="margin-top: 30px;"  :placeholder="ll[languageId].email_tips" @change="clearErr" @keyup.enter="handleLogin" class="custom-input">
                  </a-input>
                </a-form-model-item>
      
                <a-form-model-item prop="phone" v-if="type == 2">
                  <a-input-group compact style="margin-top:30px; display: flex; flex-direction: row;">

                    <div class="areaCode" style="display: flex;cursor: pointer;" @click.stop="change_start_choose"> 
                        <div >{{userInfo.areaCode ? userInfo.areaCode : ll[languageId].please_choose}}</div>
                        <div v-if="start_choose" class="start_choose">
                            <div v-for="code in areaCodes"    :key="code.name" :value="code.areaCode" @click.stop="changeAreaCode(code)" class="codeItem" style="height: 40px;line-height: 40px;">
                              <div style="display: flex; justify-content: space-between;color:#000; ">
                                  <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: flex;justify-content: flex-start;align-items: center;width: 85%;">
                                    <img :src="code.img" alt="flag" style="width: 20px; height: 14px;" />
                                    <div style="margin-left:10px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: block;;">{{ code.enName }}（{{ code.name }}）</div>
                                  </div>
                                  <div style="width:15%;text-align: right;">{{ code.areaCode }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a-input
                      class="custom-input"
                      size="large"
                      v-model="userInfo.phone"
                      :placeholder="ll[languageId].phone_tips"
                      @focus="start_choose = false;"
                      @keyup.enter="handleLogin"
                      @change="changePhone"
                      :style="{ flex: '1' }"
                    />
                  </a-input-group>
                </a-form-model-item>


                <a-form-model-item prop="password" v-if="sms_login==false">
                  <a-input-password size="large"   v-model="userInfo.password" :placeholder="ll[languageId].password_tips" @keyup.enter="handleLogin" @change="clearErr" class="custom-password-input">
                  </a-input-password>
                </a-form-model-item>

                
                <a-form-model-item prop="code" v-if="type == 2 && sms_login==true">
                  <a-input-group compact style=" display: flex; flex-direction: row;">

                    
                    <a-input
                      class="custom-input"
                      size="large"
                      v-model="userInfo.code"
                      :placeholder="ll[languageId].verification_tips"
                      @focus="start_choose = false;"
                      @change="clearErr"
                      :style="{  }"
                    />

                    <div class="ant-input " style="width:50%;cursor: pointer;height: 52px;text-align: center;display: flex;align-items: center;justify-content: center;padding: 0 10px;background-color: #275B52;color: #fff;" @click.stop="getCode"> 
                        <div v-if="is_send==0 && reSendTime<=0">{{ll[languageId].verification_code}}</div>
                        <div v-if="is_send==1">{{ll[languageId].sending}}</div>
                        <div v-if="is_send==2 && reSendTime>0">{{reSendTime}}s</div>
                        <div v-if="is_send==2 && reSendTime <=0">{{ll[languageId].reSend}}</div>
                    </div>
                  </a-input-group>
                </a-form-model-item>

               
                <div style="display: flex;justify-content: space-between;">
                  <div  style="font-size:12px;;padding-top: 0px;width:40%;color: #38383D;cursor: pointer;" @click.stop="update_pwd=1;"><div v-if="sms_login==false">{{ll[languageId].forget_password}}</div></div>
                  <div v-if="errMessage" style="font-size: 12px;color:#275B52 ;width:60%;text-align: right;">{{errMessage}}</div>
                </div>
                <a-form-model-item style="margin-bottom: 0;">
                  <a-button size="large" type="primary" 
                  style="margin-top:40px;width:100%;height: 52px;font-size:16px;margin-bottom: 0;font-weight: bold;" :style="errMessage? 'opacity: 0.5;cursor: not-allowed;':''"
                   @click.stop="handleLogin" :loading="loadingLogin">{{ll[languageId].login}}</a-button>

                   <div v-if="type == 2" style="font-size:12px;;padding-top: 0px;color: #275B52;cursor: pointer;" 
                   @click.stop="sms_login=!sms_login;userInfo.password=''">{{sms_login==true?ll[languageId].password_login :ll[languageId].sms_login}}</div>

                </a-form-model-item>
              </a-form-model>
            </div>

            <div v-if="update_pwd==1" style="position: relative;display: flex;align-items: center;height: 100%; width: 302px;height: 394px;  justify-content: center;">
              <div style="font-size: 12px;color: #626366;">{{ll[languageId].update_password_tips}}</div>
              <a-button size="large" type="primary" style="font-size: 16px;font-weight: bold;height: 52px;position: absolute;bottom: 10px;width:100%;border:1px solid #000;color:#000;background-color: #fff;    box-shadow: none;text-shadow: none;"  :loading="loadingLogin"  @click.stop="update_pwd=0;">{{ll[languageId].return_login}}</a-button>
            </div>
        </div>
        </div>

        <div v-if="sms_login==true && (this.userInfo.areaCode =='+1')" style="width: 100%;margin-top: 20px;color:#000;;">
            <div style="width: 375px;float: right">
              <div style="display: flex;flex-direction: row;cursor: pointer;" @click="is_read_privacy_go">
                  <img v-if="is_read_privacy" src="@/assets/pc/r-select.png" alt="logo" style="width:20px;height:20px;">
                  <img v-if="!is_read_privacy" src="@/assets/pc/r-unselect.png" alt="logo" style="width:20px;height:20px;">
                  <div style="margin-left: 15px;">Read and agree <a href="/privacy.html" target="_blank">《Privacy policy》</a></div>
                </div>

                <div style="display: flex;flex-direction: row;margin-top:10px;cursor: pointer;" @click="is_agree_receive_go">  
                  <img v-if="is_agree_receive" src="@/assets/pc/r-select.png" alt="logo" style="width:20px;height:20px;">
                  <img v-if="!is_agree_receive" src="@/assets/pc/r-unselect.png" alt="logo" style="width:20px;height:20px;">
                  <div style="margin-left: 15px;">I agree to receive text message</div>
                </div>

               
                <div style="margin-top:10px; ">Message and data may rates apply. Enter your phone
number to receive one time passcode message to your
phone from Eartech. Msg may vary Reply “HELP" for help
Reply “STOP" to cancel.</div>

            </div>
        </div>
        
      </div>
    </div>
     
</template>

<script>
// import  storage from 'store'
import {refreshLogin, login,getAreaCode,getCode} from '@/api/api'
import { setToken,removeToken,getLanguage,setLanguage} from '@/api/auth'
import {loginLanguage} from "@/utils/language/pc/login"

export default {
  data(){
    return {
      userInfo:{
        username:"",
        password:"",
        areaCode: '+86' // 默认区号
      },
      areaCodes: [],
   
      loadingLogin:false,
      redirect:'/',
      type:1,
      update_pwd:0,
      errMessage:0,
      languageId:"en",
      ll: loginLanguage,
      rules: {},
      start_choose:false,
      sms_login:false,
      is_send:0,

      is_read_privacy:false,
      is_agree_receive:false,
      reSendTime: 0,

    }
  },
  mounted(){
    let ll = getLanguage()

    if(! (ll == "" || ll == undefined)) {
      this.languageId = ll
    } 
    console.info("this.languageId",this.languageId)
    setLanguage(this.languageId)

    this.getAreaCodes();
    //console.log(this.$route);
    if (this.$route.query.redirect){
      this.redirect = this.$route.query.redirect
    }

    this.refreshLogins();
    this.rules = {
        email: [{ required: true, message: this.ll[this.languageId].email_reqired, trigger: 'change' }],
        phone: [],
        password: [
          { required: true, message: this.ll[this.languageId].password_reqired, trigger: 'change' }
        ],
      }
   
  },
 
  watch: {
    
    type(newValue,oldValue) {
      console.info("type",newValue,oldValue)
    

      // 先清除现有的验证状态
      this.$refs.loginFormModel.clearValidate(['email', 'phone']);
      this.errMessage = '';
      // 根据type值调整验证规则
      if (newValue == '1') {
        this.userInfo.phone = ''
        this.rules.email = [{ required: true, message: this.ll[this.languageId].email_reqired, trigger: 'change' }];
        this.rules.phone = [];
      } else if (newValue == '2') {
        this.rules.phone = [{ required: true, message: this.ll[this.languageId].phone_reqired, trigger: 'change' }];
        this.rules.email = [];
        this.userInfo.email = ''

      } else {
        this.rules.email = [];
        this.rules.phone = [];
      }
    }
  },

  methods: {
    refreshLogins(){
      refreshLogin({"uri":""}).then(ret => {
            if(ret.data.code == 200) {
              setToken(ret.data.data.token)
              setTimeout(() => {
                this.loadingLogin = false
                this.$router.push({ path: this.redirect })
              }, 500)
            } else {
              removeToken()
              console.info("dddd",ret.data.code,ret.data)
            }
          }).catch(() => {
          })
    },
    is_agree_receive_go(){
      this.is_agree_receive = !this.is_agree_receive
    },
    is_read_privacy_go(){
      this.is_read_privacy = !this.is_read_privacy
    },
    getCode() {
      if(this.reSendTime>0) {
        return;
      }
      if(!this.userInfo.areaCode) {
          return this.$message.error(this.ll[this.languageId].please_choose_area)
        }
      if(!this.userInfo.phone) {
        return this.$message.error(this.ll[this.languageId].phone_tips)
      }
      if(this.sms_login==true && this.userInfo.areaCode=='+1') {
        if(!this.is_read_privacy) {
          return this.$message.error("Please read and 《Privacy policy》")
        }
        if(!this.is_agree_receive) {
          return this.$message.error("Please agree to receive text message")
        }
       
      }
      this.is_send = 1
      this.userInfo.type="1"
      getCode(this.userInfo).then(ret => {
            // storage.set("Access-Token", ret.token, 8 * 60 * 60 * 1000)
            console.info(" ret ret", ret)
            if(ret.data.code == 200) {
              this.is_send = 2
              console.info("ret.data.reSendTime",ret.data.data.reSendTime,ret.data)
              this.reSendTime = ret.data.data.reSendTime || 121

              let timer = setInterval(() => {
                if (this.reSendTime <= 0) {
                    clearInterval(timer);
                } else {
                    this.reSendTime--;
                    console.info("this.reSendTime",this.reSendTime)
                }
              }, 1000);

            } else {
              this.is_send = 0

              return this.$message.error(ret.data.msg)
            }
          }).catch(() => {
          })
    },
    change_start_choose(){
      this.start_choose = !this.start_choose
    },
    changeAreaCode(newVal) {
      console.info("newValnewVal",newVal);
      this.userInfo.areaCode = newVal.areaCode;
      this.start_choose = false;
      this.clearErr();
      this.reSendTime = 0;
      this.is_send = 0;
      console.info("this.userInfo.areaCode",this.userInfo.areaCode)
    },
    clearErr(){
      this.errMessage = '';
    },
    changePhone(){
      this.errMessage = '';
      this.reSendTime = 0;
      this.is_send = 0;
    },
    getAreaCodes(){
      console.info("dddddd")
   
      getAreaCode({}).then(ret => {
            // storage.set("Access-Token", ret.token, 8 * 60 * 60 * 1000)
            console.info(" ret ret", ret)
            if(ret.data.code == 200) {
              this.userInfo.code = ""
              this.areaCodes = ret.data.data
            }
          }).catch(() => {
          })
    },
    
    handleLogin(){
    console.info("this.userInfo",this.userInfo)
      if(this.errMessage) {
        return;
      }
      if(this.userInfo.phone) {
        this.userInfo.email = ''
        if(!this.userInfo.areaCode) {
          return this.$message.error(this.ll[this.languageId].please_choose_area)
        }
        if(this.userInfo.code) {
          this.userInfo.password=""
        }
        if(!this.userInfo.code && this.sms_login==true) {
          return this.$message.error(this.ll[this.languageId].verification_tips)
        }
        if(!this.userInfo.password && this.sms_login==false) {
          return this.$message.error(this.ll[this.languageId].enter_password_tips)
        }
      }
      if(this.userInfo.email) {
        this.userInfo.phone = ''
      }
      this.userInfo.source = "2"
      this.$refs.loginFormModel.validate(valid => {
        if (valid) {
          this.loadingLogin = true
          login(this.userInfo).then(ret => {
            // storage.set("Access-Token", ret.token, 8 * 60 * 60 * 1000)
            if(ret.data.code != 200) {
              this.errMessage = ret.data.msg
              this.loadingLogin = false
              // this.is_send=0;
            }else {
              setToken(ret.data.data.token)
              console.info("ret",ret)
              setTimeout(() => {
                this.loadingLogin = false
                this.$router.push({ path: this.redirect })
              }, 500)
            }
          
          }).catch(() => {
            this.loadingLogin = false
          })
        }
      })
    }
  }
}
</script>
<!-- <style scoped>
.custom-password-input:hover {
  border-color: #40a9ff; /* Change the border color on hover */
  box-shadow: 0 0 8px rgba(64, 169, 255, 0.6); /* Add a box shadow on hover */
}

.custom-password-input:focus {
  border-color: #1890ff; /* Change the border color on focus */
  box-shadow: 0 0 8px rgba(24, 144, 255, 0.6); /* Add a box shadow on focus */
  outline: none; /* Remove the default outline */
}
</style> -->

<style>
body {
  font-family: PingFangSC, PingFang SC;
    /* 设置渐变背景从左上角到右下角 */
    /* background: linear-gradient(to bottom right, #E1EDFD, #F3F6FF); */
    background: #EFF2EE;
    /* 确保 body 元素填满整个视口 */
    height: 100vh;
    margin: 0;
    padding: 0;
}
.codeItem{
  padding-left: 10px;
    padding-right: 10px;
}
.codeItem:hover{
  background: #eee;
}
.start_choose{
  position: absolute;width: 302px;z-index: 1000;
  height: 300px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(217, 217, 217);top: 51px;left: -1px;
  border-top:none;
  max-height: 600px;overflow-y: auto;
  cursor: pointer;
}
.areaCode{
  width: 25%;border:1px solid #d9d9d9;position: relative;    display: flex;
    align-items: center;
    justify-content: center;background-color: #F9FAF9;

}
.areaCode:hover {
  background-color: #fff;
  border-color:#DBDBDB;
  box-shadow: 0 0 8px#DBDBDB;
}
#content{
  position:absolute;
  top:50%;
  left:50%;
  width:1027px;
  /* height:571px; */
  margin-top:-285px;
  margin-left:-513px;
  flex-direction: column;
  display: flex;

}
.ant-select-lg .ant-select-selection--single{
  height: 52px;
}
.ant-select-lg .ant-select-selection__rendered {
    line-height: 52px;
}
.ant-select-arrow .ant-select-arrow-icon svg{
  color:rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.ant-select-selection {
  background-color: #FAFBFD;
  font-size: 14px;
  height: 52px;;
  border: 0;
  overflow: hidden;
}
.ant-select-selection:hover {
  background-color: #fff;
  border-color:#DBDBDB;
  box-shadow: 0 0 8px#DBDBDB;
}
.ant-select-selection:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px #DBDBDB;
}
.custom-input {
  font-size: 14px;
  background: #F9FAF9;
  border-radius: 2px;
  height: 52px;;
}
.custom-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px #DBDBDB;
}
.custom-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px #DBDBDB;
}
.custom-password-input  {
  background-color: #F9FAF9;
  font-size: 14px;
  height: 52px;;
  border-radius: 2px;

}

.ant-input-affix-wrapper .ant-input {
    position: relative;
    text-align: inherit;
    border:none;
    height: 100%;
    font-size: 14px;
    background: #F9FAF9;
    border: 1px solid #d9d9d9;
    border-radius: 2px;

}
.custom-password-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px  #DBDBDB;
}
.custom-password-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px  #DBDBDB;
}

.ant-input-affix-wrapper .ant-input:not(:last-child):hover{
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px  #DBDBDB;
  border: 1px solid #d9d9d9;

}
.ant-input-affix-wrapper .ant-input:hover {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px  #DBDBDB;
}
.ant-input-affix-wrapper .ant-input:focus {
  background-color: #fff;
  border-color: #DBDBDB;
  box-shadow: 0 0 8px  #DBDBDB;
}
.ant-form-explain{
  font-size: 12px;
}
.ant-btn-primary{
  background-color: #000;
  border-color:#000;
  border-radius: 2px;
}
.ant-btn-primary:hover{
  background-color: #000;
  border-color:#000
}
.ant-btn-primary:focus{
  background-color: #000;
  border-color:#000
}


</style>