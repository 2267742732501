const vitransferLanguage = {
  "zh":{
    "timeList":[
      {"id":"86400","name":"24小时","desc":"上传后24小时内有效"},
      {"id":"43200","name":"12小时","desc":"上传后12小时内有效"},
      {"id":"7200","name":"2小时","desc":"上传后2小时内有效"},
      {"id":"1800","name":"30分钟","desc":"上传后30分钟内有效"},
    ],
    "language": "语言",
    "device_name": "设备名称",
    "current_device": "当前设备",
    "choose_device": "选择设备",
    "choose_paper_import": "选择导入类型",
    "choose_language": "选择语言",
    "exit": "退出",
    "paper_name": "笔记",
    "meeting_name": "会议",
    "learning_name": "学习",
    "picking_name": "随记",
    "memo_name": "便签",
    "daily_name": "日程",
    "other_name": "其他",
    "name": "名称",
    "type": "类型",
    "size": "大小",
    "time": "时间",
    "operation": "操作",
    "download": "下载",
    "delete": "删除",
    "del": "删除",
    "import": "导入",
    "return":"返回上一级",
    "search_all":"搜索全部文件",
    "selected":"已选中",
    "selected_file":" 文件/文件夹",
    "file":"文件",
    "folder":"文件夹",
    "no_data":"暂无数据",
    "confirm_delete":"确认删除",
    "confirm_delete_file":"确认删除文件吗？",
    "device_number":"序列号",
    "send_to":"发送至",
    "download_list":"下载列表",
    "scan":"扫码或输入地址",
    "share":"分享",
    "download_code":"下载二维码",
    "copy_link":"复制链接",
    "share_tips":"注：分享的链接/二维码有效期为12小时",
    "choose_format":"选择格式",
    "please_choose":"请选择文件夹或者文件",
    "please_choose_format":"请选择格式",
    "format_not_exists":"该格式的文件不存在",
    "copy_success":"复制成功",
    "copy_fail":"复制失败",
    "close_download_tips":"有正在下载的文件，不能关闭列表。",
    "choose_send_device":"请选择同步的设备",
    "send_success":"同步成功",
    "send_fail":"同步出错",
    "cancel":"取消",
    "ok":"确认",
    "send":"同步",
    "no_device":"暂无设备，请在机器上重新登录，即可自动添加设备",
    "current_no_device":"暂无设备",
    "current_device_tips":"当前设备",
    "send_loading_text":"同步中",
    "loading_text":"加载中",
    "send_error":"同步失败",
    "try_download":"重试",
    "downloading":"下载中",
    "error":"服务异常",
    "import_learning":"导入书籍",
    "import_meeting":"导入模板",
    "import_paper":"导入",
    "support_learning":"支持PDF、EPUB格式",
    "support_meeting":"支持PNG格式",
    "support_paper":"支持PDF格式",
    "outTime":"提示：为保证用户数据的隐私安全，上传的文件%s后自动清空",
    "sync":"同步",
    "max_size":"文件的大小不能超过400M",
    "file_time":"文件有效时间",

    "support_png":"只支持png格式",
    "share_to":"链接复制成功，赶紧分享给朋友吧！",
    "create_code":"生成二维码",
    "so":"等文件",
    "valid":"链接/二维码有效期为 12 小时",
    "download_phone":"下载到手机",
    "yes":"知道了",
    "format_error":"不支持该格式的文件",
  },

  "en":{
    "timeList":[
      {"id":"86400","name":"24 Hours","desc":"Expires 24 hours after upload"},
      {"id":"43200","name":"12 Hours","desc":"Expires 12 hours after upload"},
      {"id":"7200","name":"2 Hours","desc":"Expires 2 hours after upload"},
      {"id":"1800","name":"30 Minutes","desc":"Expires 30 minutes after upload"},
    ],

    "language": "Language",
    "device_name": "Device Name",
    "current_device": "current device name",
    "choose_device": "choose device",
    "choose_paper_import": "choose import paper type",
    "choose_language": "choose language",
    "exit": "Exit",
    "paper_name": "Paper",
    "meeting_name": "Meeting",
    "learning_name": "Learning",
    "picking_name": "Picking",
    "memo_name": "Memo",
    "daily_name": "Daily",
    "other_name": "Other",
    "name": "Name",
    "type": "Type",
    "size": "Size",
    "time": "DateTime",
    "operation": "Operation",
    "download": "Download",
    "delete": "Delete",
    "del": "Del",
    "import": "Import",
    "return":"Return Pre",
    "search_all":"Search all file",
    "selected":"",
    "selected_file":" files/folders selected",
    "file":"file",
    "folder":"folder",
    "no_data":"no data",

    "confirm_delete":"confirm delete",
    "confirm_delete_file":"confirm delete file？",
    "send_to":"To",
    "download_list":"download list",
    "device_number":"device number",
    "scan":"Scan the code or enter the address",
    "share":"Share",
    "download_code":"Download",
    "copy_link":"Copy Link",
    "share_tips":"Note: The shared link/QR code is valid for 12 hours",
    "choose_format":"choose format",
    "please_choose":"Please choose folder or file",
    "please_choose_format":"Please choose format",
    "format_not_exists":"The format not exists",
    "copy_success":"Copy success",
    "copy_fail":"Copy fail",
    "close_download_tips":"There are files being downloaded and the list cannot be closed.",
    "choose_send_device":"please choose sync device",
    "send_success":"Sync success",
    "send_fail":"Sync fail",
    "cancel":"Cancel",
    "ok":"Confirm",
    "send":"Sync",
    "no_device":"Please log in again on the machine to automatically add the device",
    "current_no_device":"no device",
    "current_device_tips":"current device",
    "send_loading_text":"Syncing",
    "loading_text":"Loading",
    "send_error":"sync fail",
    "try_download":"retry",
    "downloading":"downloading",
    "error":"server error",
    "import_learning":"Import Book",
    "import_meeting":"Import Template",
    "import_paper":"Import",
    "support_learning":"Support PDF、EPUB",
    "support_meeting":"Support PNG",
    "support_paper":"Support PDF",
    "outTime":"Note: To ensure the privacy and security of user data, uploaded files will be automatically cleared after %s.",
    "sync":"Sync",
    "max_size":"The file size exceeds 400MB and cannot be uploaded.",
    "file_time":"File Expires in",

    "support_png": "Only supports PNG format",
    "share_to": "The link has been copied successfully, please share it with your friends quickly",
    "create_code": "Generate QR code",
    "so": " and other files",
    "valid": "The link/QR code is valid for 12 hours",
    "download_phone": "Download to mobile phone",
    "yes": "Yes",
    "format_error":"Files in this format are not supported",
  },
  "de":{
    "timeList":[
        {"id":"86400","name":"24 Stunden","desc":"Verfällt 24 Stunden nach Hochladen"},
        {"id":"43200","name":"12 Stunden","desc":"Verfällt 12 Stunden nach Hochladen"},
        {"id":"7200","name":"2 Stunden","desc":"Verfällt 2 Stunden nach dem Hochladen"},
        {"id":"1800","name":"30 Minuten","desc":"Läuft 30 Minuten nach dem Hochladen ab"},
      ],
      "language": "Sprache",
      "device_name": "Name der Ausrüstung",
      "current_device": "Aktueller Gerätename",
      "choose_device": "Gerät auswählen",
      "choose_paper_import": "Papiertyp für Import auswählen",
      "choose_language": "Sprache auswählen",
      "exit": "Beenden",
      "paper_name": "Paper",
      "meeting_name": "Meeting",
      "learning_name": "Learning",
      "picking_name": "Picking",
      "memo_name": "Memo",
      "daily_name": "Daily",
      "other_name": "Other",
      "name": "Name",
      "type": "Art",
      "size": "Größe",
      "time": "Datum Uhrzeit",
      "operation": "Vorgang",
      "download": "Herunterladen",
      "delete": "Löschen",
      "import": "Import",
      "return": "Zurück Vor",
      "search_all": "Alle Dateien suchen",
      "selected": "",
      "selected_file": "Ausgewählte Dateien/Ordner",
      "file": "Datei",
      "folder": "Ordner",
      "no_data": "keine Daten",
      "confirm_delete": "Löschen bestätigen",
      "confirm_delete_file": "Löschen der Datei bestätigen",
      "send_to": "Speichern",
      "download_list": "Liste herunterladen",
      "device_number": "Gerätenummer",
      "scan": "Code einscannen oder Adresse eingeben",
      "share": "Weiterleiten",
      "download_code": "Herunterladen",
      "copy_link": "Link kopieren",
      "share_tips": "Hinweis: Der freigegebene Link/QR-Code ist 24 Stunden gültig.",
      "choose_format": "Format auswählen",
      "please_choose_format": "Bitte Format wählen",
      "format_not_exists": "Format existiert nicht",
      "copy_success": "Kopieren erfolgreich",
      "copy_fail": "Kopieren fehlgeschlagen",
      "close_download_tips": "Dateien werden heruntergeladen und die Liste kann nicht geschlossen werden.",
      "choose_send_device": "Bitte wählen Sie ein Synchronisierungsgerät",
      "send_success": "Synchronisation erfolgreich",
      "send_fail": "Synchronisation fehlgeschlagen",
      "cancel": "Abbrechen",
      "ok": "Bestätigen",
      "send": "Synchronisieren",
      "no_device": "Um das Gerät automatisch hinzuzufügen, melden Sie sich bitte erneut am Computer an.",
      "current_no_device": "kein Gerät",
      "current_device_tips": "aktuelles Gerät",
      "send_loading_text": "Synchronisieren",
      "loading_text": "Laden",
      "send_error": "Synchronisation fehlgeschlagen",
      "try_download": "erneut versuchen",
      "downloading": "Herunterladen",
      "error": "Server-Fehler",
      "import_learning":"Import Book",
      "import_meeting":"Import Template",
      "import_paper":"Import",
      "support_learning": "PDF、EPUB",
      "support_meeting": "PNG",
      "support_paper": "PDF",
      "outTime": "Hinweis: Hochgeladene Dateien werden nach %s automatisch gelöscht, um den Datenschutz und die Sicherheit der Benutzerdaten zu gewährleisten.",
      "sync": "Synchronisieren",
      "max_size": "Die Datei ist größer als 100MB und kann nicht hochgeladen werden.",
      "file_time": "Datei läuft ab in",
      "support_png": "Unterstützt nur PNG-Format",
      "share_to": "Der Link wurde erfolgreich kopiert, bitte teilen Sie ihn schnell mit Ihren Freunden",
      "create_code": "QR-Code generieren",
      "so": " und andere Dateien",
      "valid": "Der Link/QR-Code ist für 12 Stunden gültig",
      "download_phone": "Download auf Handy",
      "yes": "bestimmen",
      "format_error":"Dateien in diesem Format werden nicht unterstützt",
    },
  "fr":{
    "timeList":[
        {"id":"86400","name":"24 heures","desc":"Expire 24 heures après le téléchargement"},
        {"id":"43200","name":"12 heures","desc":"Expire 12 heures après le téléchargement"},
        {"id":"7200","name":"2 heures","desc":"Expire 2 heures après le téléchargement"},
        {"id":"1800","name":"30 minutes","desc":"Expire 30 minutes après le téléchargement"},
      ],
      "language": "Langue",
      "device_name": "Nom de l'appareil",
      "current_device": "nom de l'appareil actuel",
      "choose_device": "choisir l'appareil",
      "choose_paper_import": "choisir le type du papier à importer",
      "choose_language": "choisir la langue",
      "exit": "Quitter",
      "paper_name": "Paper",
      "meeting_name": "Meeting",
      "learning_name": "Learning",
      "picking_name": "Picking",
      "memo_name": "Memo",
      "daily_name": "Daily",
      "other_name": "Other",
      "name": "Nom ",
      "type": "Type ",
      "size": "Taille",
      "time": "Date et heure",
      "operation": "Fonctionnement",
      "download": "Télécharger",
      "delete": "Supprimer",
      "import": "Import",
      "return": "Annuler Pre",
      "search_all": "Rechercher tous les fichiers",
      "selected": "",
      "selected_file": "fichiers/dossiers sélectionnés",
      "file": "fichier",
      "folder": "dossier",
      "no_data": "pas de données",
      "confirm_delete": "confirmer la suppression",
      "confirm_delete_file": "confirmer la suppression du fichier？",
      "send_to": "Vers",
      "download_list": "liste des téléchargements",
      "device_number": "numéro de l'appareil",
      "scan": "Scannez le code ou entrez l'adresse",
      "share": "Partager",
      "download_code": "Télécharger",
      "copy_link": "Copier le lien",
      "share_tips": "Remarque : le lien partagé/code QR est valable pendant 24 heures.",
      "choose_format": "choisir le format",
      "please_choose_format": "Veuillez choisir le format",
      "format_not_exists": "Le format n'existe pas",
      "copy_success": "Copie réussie",
      "copy_fail": "Échec de la copie",
      "close_download_tips": "Des fichiers sont en cours de téléchargement et la liste ne peut pas être fermée.",
      "choose_send_device": "Veuillez choisir l'appareil de synchronisation",
      "send_success": "Synchronisation réussie",
      "send_fail": "Échec de la synchronisation",
      "cancel": "Annuler",
      "ok": "Confirmer",
      "send": "Synchronisation",
      "no_device": "Veuillez vous connecter à nouveau sur la machine pour ajouter automatiquement l'appareil.",
      "current_no_device": "pas d'appareil",
      "current_device_tips": "appareil actuel",
      "send_loading_text": "Synchronisation",
      "loading_text": "Chargement",
      "send_error": "échec de la synchronisation",
      "try_download": "réessayer",
      "downloading": "téléchargement",
      "error": "erreur de serveur",
      "import_learning":"Import Book",
      "import_meeting":"Import Template",
      "import_paper":"Import",
      "support_learning": "Support PDF、EPUB",
      "support_meeting": "Support PNG",
      "support_paper": "Support PDF",
      "outTime": "Remarque : pour garantir la confidentialité et la sécurité des données des utilisateurs, les fichiers téléchargés seront automatiquement effacés après %s.",
      "sync": "Synchronisation",
      "max_size": "La taille du fichier dépasse 400MB et ne peut pas être téléchargée.",
      "file_time": "Le fichier expire dans",

      "support_png": "Seul le format PNG est supporté",
      "share_to": "Le lien a été copié avec succès, dépêchez - vous de le partager avec vos amis!",
      "create_code": "Générer un Code QR",
      "so": " fichiers tels que",
      "valid": "Le lien / Code QR est valable 12 heures",
      "download_phone": "télécharger sur mobile",
      "yes": "Déterminer",
      "format_error":"Les fichiers de ce format ne sont pas pris en charge",
    },
    "es":{
      "timeList":[
          {"id":"86400","name":"24 Horas","desc":"Caduca 24 horas después de la subida"},
          {"id":"43200","name":"12 Horas","desc":"Caduca 12 horas después de la subida"},
          {"id":"7200","name":"2 horas","desc":"Caduca 2 horas después de la subida"},
          {"id":"1800","name":"30 minutos","desc":"Caduca 30 minutos después de la subida"},
        ],
        "language": "Idiomas",
        "device_name": "Nombre del dispositivo",
        "current_device": "nombre del dispositivo actual",
        "choose_device": "elegir dispositivo",
        "choose_paper_import": "elegir tipo de papel de importación",
        "choose_language": "elegir idioma",
        "exit": "Salir ",
        "paper_name": "Paper",
        "meeting_name": "Meeting",
        "learning_name": "Learning",
        "picking_name": "Picking",
        "memo_name": "Memo",
        "daily_name": "Daily",
        "other_name": "Other",
        "name": "Nombre",
        "type": "Tipo",
        "size": "Tamaño",
        "time": "FechaHora",
        "operation": "Funcionamiento",
        "download": "Descargar",
        "delete": "Eliminar",
        "import": "Import",
        "return": "Volver Pre",
        "search_all": "Buscar todo el archivo",
        "selected": "",
        "selected_file": "archivos/carpetas seleccionados",
        "file": "archivo",
        "folder": "carpeta",
        "no_data": "sin datos",
        "confirm_delete": "confirmar eliminar",
        "confirm_delete_file": "¿Confirmar eliminar archivo？",
        "send_to": "A",
        "download_list": "descargar lista",
        "device_number": "número de dispositivo",
        "scan": "Escanea el código o introduce la dirección",
        "share": "Compartir",
        "download_code": "Descargar",
        "copy_link": "Copiar Enlace",
        "share_tips": "Nota: El enlace compartido/código QR es válido durante 24 horas",
        "choose_format": "elegir formato",
        "please_choose_format": "Por favor, elige el formato",
        "format_not_exists": "El formato no existe",
        "copy_success": "Copia exitosa",
        "copy_fail": "Fallo al copiar",
        "close_download_tips": "Se están descargando archivos y no se puede cerrar la lista.",
        "choose_send_device": "Por favor, elige el dispositivo de sincronización",
        "send_success": "Sincronización exitosa",
        "send_fail": "Fallo al sincronizar",
        "cancel": "Cancelar",
        "ok": "Confirma",
        "send": "Sincronizar",
        "no_device": "Por favor, inicia sesión de nuevo en la máquina para añadir automáticamente el dispositivo",
        "current_no_device": "ningún dispositivo",
        "current_device_tips": "dispositivo actual",
        "send_loading_text": "Sincronización",
        "loading_text": "Cargando",
        "send_error": "fallo al sincronizar",
        "try_download": "reintentar",
        "downloading": "descargando",
        "error": "error del servidor",
        "import_learning":"Import Book",
        "import_meeting":"Import Template",
        "import_paper":"Import",
        "support_learning": "PDF、EPUB",
        "support_meeting": "PNG",
        "support_paper": "PDF",
        "outTime": "Nota: Para garantizar la privacidad y seguridad de los datos de los usuarios, los archivos cargados se borrarán automáticamente después de %s.",
        "sync": "Sincronizar",
        "max_size": "El tamaño del archivo supera los 400MB y no se puede subir.",
        "file_time": "El Archivo Caduca en",

        "support_png": "Solo se admite el formato png",
        "share_to": "El enlace se copió con éxito, ¡ comparte con tus amigos!",
        "create_code": "Generar Código qr",
        "so": " y otros documentos",
        "valid": "El período de validez del enlace / código QR es de 12 horas",
        "download_phone": "Descargar al teléfono móvil",
        "yes": "Determinar",
        "format_error":"Archivos que no admiten este formato",
      }
}

export {
  vitransferLanguage
}